<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" v-if="terminal=='pc'">
      <el-col :span='6'>
        <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini"></el-input>
      </el-col>
      <el-button class="margin-left" size="mini" @click="srchRecord">查找</el-button>
      <el-button type="success" size="mini" @click="addDiv=true">添加记录</el-button>
    </el-row>
    <div v-else>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini" @keyup.enter.native="srchRecord"></el-input>
        </el-col>
        <el-col :span=6>
          <el-button style="width:100%" size="mini" @click="srchRecord">查找</el-button>
        </el-col>
        <el-col :span="6">
          <el-button style="width:100%" type="success" size="mini" @click="addDiv=true">添加记录</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column label="日期" prop="time" width="100"></el-table-column>
      <el-table-column label="内容" prop="detail"></el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-image v-for="(item,index) in scope.row.notes" :key="index" :src="$pic(item)" style="width:100px;cursor: pointer;" :preview-src-list="$picList(scope.row.notes)"></el-image>
          <!-- <img v-for="(item,index) in scope.row.notes" :key="index" :src="picHost+item" @click="showPic(item)" style="width:100px;cursor: pointer;"> -->
        </template>
      </el-table-column>
      <el-table-column label="操作" width='60'>
        <template slot-scope="scope">
          <el-button type='text' @click="del(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="terminal=='pc'" background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-pagination v-else background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize"  @current-change="handleCurrentChange" layout="total, prev, pager, next" :total="total"></el-pagination>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetInfo()">
      <el-form :model="recordInfo" ref="recordInfo" :label-position="terminal=='pc'?'right':'top'" class="text-left">
        <el-form-item label="日期" :label-width="formLabelWidth" class="text-left">
          <el-date-picker v-model="recordInfo.time" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="3" v-model="recordInfo.detail" auto-complete="off" maxlength="70" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="图片" :label-width="formLabelWidth" class="text-left">
          <el-upload style='align-self:center' :action="HOST+'/index/uploadFile?name=notes'" accept="jpg/jpeg/png" name="notes" :data="uploadData" :auto-upload="true" :on-success="showRes" :before-upload='showloading' class="margin-bottom" list-type="picture-card" :on-remove="handleRemove"
            v-if="recordInfo.notes.length<5">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      list: [],
      keyword: "",
      page: 1,
      total: 0,
      addDiv: false,
      recordInfo: {
        time: this.getDate(),
        detail: "",
        notes: [],
      },
      tempInfo: {},
      terminal: this.getTerminal(),
    };
  },
  methods: {
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    handleRemove(file, fileList) {
      this.$axios({
        url: "/index/delPic",
        data: {
          pic: file.response.file,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.recordInfo.notes.splice(
            this.recordInfo.notes.indexOf(file.response.file),
            1
          );
        }
      });
    },
    showPic(pic) {
      this.picUrl = this.picHost + pic;
      this.picDiv = true;
    },
    getlist() {
      this.$axios({
        url: this.HOST + "/record/getSpecialList",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.list = res.list.data;
          this.total = res.list.total;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetInfo() {
      for (let item in this.tempInfo) {
        this.recordInfo[item] = this.tempInfo[item];
      }
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/record/addspecial",
        method: "post",
        data: {
          info: this.recordInfo,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.addDiv = false;
        }
      });
    },
    showRes(res) { console.log(res)
      this.hideloading();
      if (res.status == 1) {
        this.recordInfo.notes.push(res.file);
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    srchRecord() {
      this.page = 1;
      this.getlist();
    },
    del(scope) {
      this.$confirm("确定要删除本记录吗？本操作不可恢复！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/record/delSpecial",
          method: "post",
          data: {
            id: scope.row.id,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.getlist();
            this.$message({
              showClose: true,
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
  },
  created: function () {
    for (let item in this.recordInfo) {
      this.tempInfo[item] = this.recordInfo[item];
    }
    this.getlist();
  },
};
</script>