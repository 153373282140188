<template>
  <div>
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <!-- <el-divider>折叠内容</el-divider>
    <el-form v-if="type=='introduce'" v-loading="loading">
      <div v-for="(item,index) in details" :key="index" style="border:1px solid #ddd;border-radius: 10px;padding:10px;box-sizing: border-box;margin-bottom:10px;">
        <el-form-item label="标题">
          <el-input v-model="item.title"></el-input>
        </el-form-item>
        <el-form-item label="介绍">
          <el-input v-model="item.introduce"></el-input>
        </el-form-item>
        <el-form-item label="图标" class="text-left">
          <el-upload style='align-self:center' :action="uploadurl+'?index='+index+'&type=icon'" name="pic" :data="uploadData" :auto-upload="true" :on-success="showUpInfoImageRes" :show-file-list="false" :before-upload='showloading'>
            <img :src="HOST+'/'+item.icon" alt="" v-if="item.icon!=''" style="width:100px">
            <el-button size="mini" type="primary" plain>
              <i class="el-icon-upload el-icon--rihgt"></i>点击选择图片
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="item.sort"></el-input>
        </el-form-item>
        <el-form-item label="详情" class="text-left">
          <div style='padding:10px;margin-bottom:10px'>
            <div style="display:flex;padding:0 10px;min-height:60px;width:100%">
              <div class="introducePiclistDiv">
                <draggable v-model='item.items' :group="'people'+index" @start="drag=true" @end="drag=false" style='flex-grow:1;display:flex;border: 1px solid #ddd;margin-left: 10px;border-radius: 10px;'>
                  <li v-for="(id, i) in item.items" :index="i" :key="i">
                    <img :src="HOST+id" alt="" />
                    <div class="del">
                      <i class="el-icon-delete del" @click="delItemPic(index,i)"></i>
                      <i class="el-icon-search search" @click="showPic(id)"></i>
                    </div>
                  </li>
                </draggable>
                <el-upload class="margin-left" style='align-self:center' :action="uploadurl+'?index='+index+'&type=pic'" name="pic" :data="uploadData" :auto-upload="true" :on-success="showUpInfoImageRes" :show-file-list="false" :before-upload='showloading'>
                  <el-button size="mini" type="primary" plain>
                    <i class="el-icon-upload el-icon--rihgt"></i>点击选择图片
                  </el-button>
                </el-upload>
                <li style='display:flex;justify-items: center;'><el-button size="mini" type='danger' @click="clearDetailsItem(index)">删除</el-button></li>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-button size="mini" type="warning" @click="delItem(index)">删除</el-button>
      </div>
    </el-form>
    <div>
      <el-button @click="addDetails" size="mini">添加</el-button>
      <el-button @click="saveIntroduce" size="mini">保存</el-button>
    </div> 
    <el-divider>图文简介</el-divider>-->
    <el-form ref="form" label-width="60px">
      <el-form-item label="详情" prop="detail" :style="quillHeightAll" class="text-left">
        <div class="edit_container">
          <quill-editor v-model="detail" ref="myQuillEditor" class="editer" :options="editorOption" @ready="onEditorReady($event)" :style="quillHeight"></quill-editor>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="mdfysetting">修改</el-button>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
// 富文本样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  data() {
    return {
      type: "",
      detail: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            // ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            // [{ size: ["small", false, "large", "huge"] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            // [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["image"],
          ],
        },
      },
      itemTitleDiv: false,
      details: [],
      itemTitle: '',
      uploadurl: this.HOST + '/setting/uploadFile',
      uploadData: {
        token: sessionStorage.getItem('token'),
        uuid: sessionStorage.getItem('uuid'),
      },
      loading: false
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    title() {
      let res = '';
      switch (this.type) {
        case "xieyi":
          res = "用户使用协议";
          break;
        case "zhengce":
          res = "隐私政策";
          break;
        case "about":
          res = "关于我们";
          break;
        case "introduce":
          res = "首页简介"
          break;
      }
      document.title = res;
      return res;
    },
    quillHeight() {
      return "height:" + (document.documentElement.clientHeight - 400) + "px";
    },
    quillHeightAll() {
      return "height:" + (document.documentElement.clientHeight - 350) + "px";
    },
  },
  components: {
    quillEditor,
  },
  methods: {
    onEditorReady(editor) {
      // addQuillTitle();
    },
    showloading() {
      this.loading = true;
    },
    // 清空图片、视频按钮
    clearDetailsItem(index) {
      this.$confirm("确定清空所有文件吗?本操作不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.details[index].items = [];
      });
    },
    addDetails() {
      this.details.push({
        id: '',
        title: '',
        items: [],
        icon: '',
        introduce: '',
        sort: 0
      })
    },
    showUpInfoImageRes(res) {
      this.loading = false;
      if (res.status == 1) {
        if (res.type == 'icon') {
          this.details[res.index].icon = res.file;
        } else {
          this.details[res.index].items.push(res.file)
        }
      }
    },
    delItemPic(index, i) {
      this.details[index].items.splice(i, 1)
    },
    delItem(index) {
      this.details.splice(index, 1)
    },
    getsetting() {
      this.$axios({
        url: this.HOST + "/setting/getArticle",
        method: "post",
        data: {
          type: this.type,
        },
      }).then((res) => {
        if (res.status == 1) {
          this.detail = res.detail;
          if (this.type == 'introduce') {
            this.details = res.list;
          }
        }
      });
    },
    mdfysetting() {
      this.$confirm("确定要修改" + this.title + "吗？本操作不可恢复", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/setting/setArticle",
          method: "post",
          data: {
            detail: this.detail,
            type: this.type,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.getsetting();
            this.$message({
              type: "success",
              message: res.info,
            });
          }
        });
      });
    },
    saveIntroduce() {
      this.$axios({
        url: this.HOST + "/setting/saveIntroduce",
        method: "post",
        data: {
          info: this.details
        },
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.getsetting();
        }
      });
    },
  },
  created: function () {
    this.type = this.$route.params.type;
    this.getsetting();
  },
};
</script>
<style>
.introducePiclistDiv {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  box-sizing: border-box;
  flex-grow: 1;
}
.introducePiclistDiv li {
  margin: 10px;
  height: 100px;
  list-style: none;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.introducePiclistDiv li img {
  width: 100px;
  height: 100%;
}
.introducePiclistDiv li .del {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.introducePiclistDiv li:hover .del {
  opacity: 1;
}
.introducePiclistDiv li .del i {
  position: absolute;
  font-size: 22px;
  color: #fff;
  top: 35px;
  cursor: pointer;
}
.introducePiclistDiv li .del i.del {
  width: 22px;
  height: 22px;
  left: 55px;
}
.introducePiclistDiv li .del i.search {
  left: 25px;
}
.videolistDiv li {
  border: 1px solid #ddd;
  width: 100px;
}
</style>